body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'PT Sans', sans-serif;
  font-size: 2.2vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.login-link {
  position: absolute;
  bottom: 5px;
  left: 10px;
  text-decoration: none;
  color: #D4E6F1;
  font-family: 'VT323', monospace;
  font-size: 0.85em;
}

@media only screen and (max-width: 768px) {
  .login-link {
      font-size: 11px;
      bottom: 6.5px;
  }
}

.VT323 {
  font-family: 'VT323', monospace;
}

.margin-5 {
  margin: 5px;
}

.smalltext {
  font-size: 0.8em;
}

::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 1px rgba(16, 69, 112, 0.664);
    border-radius: 3px;
    background-color: #92b1cf;
}

::-webkit-scrollbar
{
    width: 2px;
    background-color: #3b6286;
}

::-webkit-scrollbar-thumb
{
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 1px rgba(16, 69, 112, 0.664);
    background-color: rgb(64, 87, 109);
}

